import { ApiEndpoint } from 'api/api-endpoint/api-endpoint';
import config from 'config';

const createRequestHeader = (
	token: string,
	url: string,
	apiEndpoint?: ApiEndpoint,
) => {
	const header = {
		accept: 'application/json',
		Authorization: `Bearer ${token}`,
		'x-functions-key': config.functionCode,
	};

	return header;
};

export default createRequestHeader;
