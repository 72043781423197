import React, { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
} from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import useEnvironments from 'api/hooks/use-enviornments/use-enviornments';

interface Props {
	isOpen: boolean;
	handleSelect: (selectedEnvironment: string) => void;
	email: string;
}

export const SetEnvironmentPopup: React.FC<Props> = ({
	isOpen,
	handleSelect,
	email,
}) => {
	const [environment, setEnvironment] = useState(null);

	const { allowedEnvironments, isLoading: isValidating } =
		useEnvironments(email);

	useEffect(() => {
		if (!isValidating && allowedEnvironments) {
			window.sessionStorage.setItem(
				'allowed_environments',
				JSON.stringify(allowedEnvironments),
			);
		}
	}, [allowedEnvironments, isValidating]);

	const handleSubmit = e => {
		e.preventDefault();
		window.sessionStorage.setItem('environment', environment);
		handleSelect(environment);
	};

	return (
		<Dialog open={isOpen}>
			<DialogTitle id="form-dialog-title">Choose environment</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{isValidating ? (
						<Skeleton count={2} />
					) : (
						'Choose which environment you want to work in'
					)}
				</DialogContentText>
				{isValidating ? (
					<Skeleton />
				) : (
					<FormControl sx={{ width: '100%' }}>
						<InputLabel id="environment-select-label">Environment</InputLabel>
						<Select
							variant="standard"
							labelId="environment-select-label"
							id="environment-select"
							value={environment || ''}
							onChange={e => setEnvironment(e.target.value)}
							sx={{
								width: '100%',
							}}
						>
							{allowedEnvironments &&
								allowedEnvironments.map(env => (
									<MenuItem key={env} value={env}>
										{env}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmit} color="primary" disabled={!environment}>
					Choose {environment}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
