import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import toMaterialStyle from 'material-color-hash';
import useAuthProvider from 'hooks/use-auth-provider';

const useStyles = makeStyles(_ => ({
	avatar: {
		borderRadius: '50%',
		boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.5)',
	},
}));

const UserAvatar: React.FC = () => {
	const { account } = useAuthProvider();
	const classes = useStyles();
	const userColor = toMaterialStyle(account?.username || '', 300);

	const initials = () =>
		account?.name && account.name.split(' ').map(p => p[0]);

	return (
		<Avatar
			style={{
				backgroundColor: userColor.backgroundColor,
				color: userColor.color,
			}}
			alt={account?.name}
			title={account?.name}
			classes={{ img: classes.avatar }}
		>
			{initials()}
		</Avatar>
	);
};

export default UserAvatar;
