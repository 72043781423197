import {
	useState,
	useRef,
	useEffect,
	Dispatch,
	SetStateAction,
	useCallback,
} from 'react';
import { CACHE_VERSION } from 'utils/cache/cache';

type Storage = 'localStorage' | 'sessionStorage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function usePersistedState<T = any>(
	selectedClientId: number,
	key: string,
	defaultValue: T | (() => T),
	storage: Storage = 'localStorage',
): [T, Dispatch<SetStateAction<T>>, () => void] {
	const cacheKey = `${selectedClientId}-${key}_v${CACHE_VERSION}`;
	const [state, setState] = useState<T>(() => {
		const valueInStorage = window[storage]?.getItem(cacheKey);
		if (valueInStorage) {
			return JSON.parse(valueInStorage);
		}

		return typeof defaultValue === 'function'
			? (defaultValue as () => T)()
			: defaultValue;
	});

	const prevKeyRef = useRef(cacheKey);

	useEffect(() => {
		const prevKey = prevKeyRef.current;
		if (prevKey !== cacheKey) {
			window[storage].removeItem(prevKey);
		}
		prevKeyRef.current = cacheKey;
		window[storage].setItem(cacheKey, JSON.stringify(state));
	}, [key, cacheKey, state, storage]);

	const clearState = useCallback(() => {
		window[storage].removeItem(cacheKey);
	}, [cacheKey, storage]);

	return [state, setState, clearState];
}

export { usePersistedState };
