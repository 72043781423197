import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { msalInstance } from 'utils/auth';
import { IntlProvider } from 'react-intl';
import { DuiGlobalStyles } from '@dignisia/dui';

const root = createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<IntlProvider locale={'en'}>
			<MsalProvider instance={msalInstance}>
				<DuiGlobalStyles />
				<App />
			</MsalProvider>
		</IntlProvider>
	</BrowserRouter>,
);
