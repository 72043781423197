const config: Config = window.MyConfig;

export const isDev = Boolean(config?.env === 'development');

const configFallback: Config = {
	apiUrl: 'http://localhost:7071/api',
	devUri: 'https://customer-development.dignisia.com',
	prodUri: 'https://customer.dignisia.com',
	msalRedirectUrl: 'http://localhost:3000',
	functionCode: '',
	msalTenantId: '714dd6c8-f309-49a3-a4f2-47dc5e777b36',
	msalClientId: '687b7718-7dfd-4c58-b8eb-fb0b9fd3202c',
	sftpIp: 'dignisia-sftp-dev.westeurope.azurecontainer.io',
	sftpPort: '22',
	env: 'development',
	title: 'Dignisia local',
};

const isDevelopment = process.env.NODE_ENV === 'development';

export default isDevelopment
	? {
			...config,
			apiUrl: process.env.REACT_APP_API_URL ?? configFallback.apiUrl,
			msalRedirectUrl:
				process.env.REACT_APP_MSAL_REDIRECT_URI ??
				configFallback.msalRedirectUrl,
			msalTenantId:
				process.env.REACT_APP_TENANTID ?? configFallback.msalTenantId,
			msalClientId:
				process.env.REACT_APP_CLIENTID ?? configFallback.msalClientId,
			sftpIp: process.env.REACT_APP_SFTP_IP ?? configFallback.sftpIp,
			sftpPort: process.env.REACT_APP_SFTP_PORT ?? configFallback.sftpPort,
			env: configFallback.env,
			title: configFallback.title,
	  }
	: config;
