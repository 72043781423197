import { AsyncResponse } from 'interfaces/async-response';
import useApi from '../use-api/use-api';

interface UseEnvironments extends AsyncResponse<string[]> {
	allowedEnvironments: string[];
	isLoading: boolean;
}

const useEnvironments = (email: string): UseEnvironments => {
	const { data, isValidating: isLoading } = useApi<string[]>(
		email ? `/environments/${email}` : null,
	);

	return { allowedEnvironments: data, isLoading };
};

export default useEnvironments;
