import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},

	img: {
		width: '20em',
		height: '20em',
		marginTop: '5em',
	},
}));

const NotFoundPage: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Typography variant="h6" color="textSecondary">
				Oops! You found an empty page
			</Typography>
			<Typography>The page you tried to reach does not exist</Typography>
			<img
				className={classes.img}
				src="/images/empty-page.svg"
				alt="Empty page"
			/>
		</div>
	);
};

export default NotFoundPage;
