import { msalInstance } from 'utils/auth/auth';
import { isTokenValid } from 'utils/token/token';

const checkForUnauthorizedError = (token: string) => {
	if (!isTokenValid(token)) {
		// If an API call returns 401 and the token is expired -> logout the user
		msalInstance.logoutRedirect();
	}
};

export default checkForUnauthorizedError;
