import { ApiEndpoint } from 'api/api-endpoint/api-endpoint';
import config from 'config';

const getIsApiEndpointConfigured = (apiEndpoint: ApiEndpoint = 'AdminApi') => {
	switch (apiEndpoint) {
		case 'AdminApi':
			return Boolean(config.apiUrl && config.apiUrl.length > 0);
	}
};

export default getIsApiEndpointConfigured;
