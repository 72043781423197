import checkForUnauthorizedError from 'api/authentication/check-for-unauthorized-error';
import { UserFriendlyError } from 'api/status/user-friendly-error';
import axios, { AxiosRequestConfig } from 'axios';
import { isDev } from 'config';
import { request } from 'utils/axios';

const doFetchWithCancel = async (
	axiosConfig: AxiosRequestConfig,
	token,
	encodedUri: URL,
	requestHeader,
) => {
	const res = await request
		.get(`${encodedUri}`, {
			method: 'GET',
			headers: requestHeader,
			cancelToken: axiosConfig?.cancelToken,
		})
		.catch(error => {
			if (error?.response?.data?.resultStatus === UserFriendlyError) {
				return Promise.reject(error?.response?.data?.errorMessage);
			}

			if (error && error.toString().includes('404')) {
				return Promise.reject('Endpoint was not found');
			}
			if (axios.isCancel(error) && error instanceof Error) {
				console.log('REJECTED', error.message);
			} else {
				// handle error
			}
		});

	if (res && res.status >= 400 && res.status <= 500) {
		if (res.status === 401 || res.status === 403) {
			checkForUnauthorizedError(token);
		} else {
			const ex = await res.data;
			throw new Error(isDev && ex && ex.errorMessage);
		}
	}
	if (res) {
		return await res.data;
	}
};

export default doFetchWithCancel;
