import React, { Suspense, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navigation from './components/navigation/navigation';
import LoginPage from './pages/login';
import NotFoundPage from './pages/not-found';
import routes from './pages/routes';
import './scss/index.scss';
import { SetEnvironmentPopup } from './components/set-environment-popup';
import useAuthProvider from 'hooks/use-auth-provider';
import { useOriginalPathRedirect } from 'hooks/use-original-path-redirect';
import { ThemeProvider } from '@mui/material/styles';
import { DuiSnackbarProvider, DuiTheme } from '@dignisia/dui';
import { CssBaseline } from '@mui/material';

const App: React.FC = () => {
	const { isAuthenticated, account, progress, onSignIn } = useAuthProvider();
	const location = useLocation();
	const [environment, setEnvironment] = useState(() =>
		window.sessionStorage.getItem('environment'),
	);

	useOriginalPathRedirect();

	const isLoginPage = location.pathname === '/login';

	return (
		<ThemeProvider theme={DuiTheme}>
			<DuiSnackbarProvider>
				<CssBaseline />
				{isAuthenticated && <Navigation />}
				<div
					className={!isLoginPage ? 'main-content' : ''}
					data-testid="main-content"
				>
					{isAuthenticated && (
						<SetEnvironmentPopup
							isOpen={!Boolean(environment)}
							handleSelect={env => setEnvironment(env)}
							email={account?.username}
						/>
					)}
					<Routes>
						<Route
							path="/login"
							element={
								<LoginPage
									isAuthenticated={isAuthenticated}
									progress={progress}
									onSignIn={onSignIn}
								/>
							}
						/>
						{!isAuthenticated && progress === 'none' && !account && (
							<Route path="/" element={<Navigate to="/login" />} />
						)}
						<Route path="/404" element={<NotFoundPage />} />
						{environment &&
							routes.map((route, i) => (
								<Route
									path={route.path}
									element={
										<Suspense fallback={''}>
											<route.component />
										</Suspense>
									}
									key={i}
								/>
							))}
					</Routes>
				</div>
			</DuiSnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
