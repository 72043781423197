import { useIsAuthenticated } from '@azure/msal-react';
import { fetcherWithCancel } from 'api/fetcher/fetcher-with-cancel';
import { useAbortSWR } from 'api/use-abort-swr';
import { ConfigInterface, keyInterface, responseInterface } from 'swr';

export const useApi = <Data,>(
	key?: keyInterface,
	options?: ConfigInterface<Data, Error>,
	retrieveData = true,
): responseInterface<Data, Error> & {
	abort: (text?: string) => void;
	isLoading?: boolean;
} => {
	const isAuthenticated = useIsAuthenticated();

	const result = useAbortSWR<Data, Error>(
		isAuthenticated ? retrieveData && key : null,
		fetcherWithCancel,
		options,
	);

	return result;
};

export default useApi;
