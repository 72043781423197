import { IconButton, Menu, MenuItem, Select } from '@mui/material';
import useAuthProvider from 'hooks/use-auth-provider';
import React, { useRef, useState } from 'react';
import UserAvatar from '../user-avatar';

export const ProfileMenu = () => {
	const { onSignOut } = useAuthProvider();

	const [open, setOpen] = useState(false);
	const anchorEl = useRef(null);
	const storedEnvironments = JSON.parse(
		window.sessionStorage.getItem('allowed_environments'),
	);
	const selectedEnvironment = window.sessionStorage.getItem('environment');

	return (
		<>
			<div rootRef={anchorEl}>
				<IconButton
					onClick={() => setOpen(o => !o)}
					color="inherit"
					ref={anchorEl}
				>
					<UserAvatar />
				</IconButton>
			</div>
			{anchorEl.current && (
				<Menu
					id="menu-appbar"
					anchorEl={anchorEl.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					getContentAnchorEl={null}
					open={open}
					onClose={() => {
						setOpen(false);
					}}
				>
					<MenuItem>
						<Select
							variant="standard"
							value={selectedEnvironment}
							onChange={e => {
								window.sessionStorage.setItem('environment', e.target.value);
								// eslint-disable-next-line
								window.location.href = window.location.href;
							}}
							fullWidth
							disabled={false}
						>
							{storedEnvironments
								? storedEnvironments.map(env => (
										<MenuItem key={env} value={env}>
											{env}
										</MenuItem>
								  ))
								: []}
						</Select>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setOpen(false);
							onSignOut();
						}}
					>
						Logga ut
					</MenuItem>
				</Menu>
			)}
		</>
	);
};

export default ProfileMenu;
