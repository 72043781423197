import { Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	themeIcon: {
		color: '#666',
	},
	active: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

const ListItemLink = ({ icon, path, name, exact }) => {
	const classes = useStyles();
	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<RouterLink
					to={path}
					{...itemProps}
					innerRef={ref}
					activeClassName={classes.active}
					exact={exact}
				/>
			)),
		[path, exact, classes.active],
	);

	return (
		<ListItem component={renderLink}>
			<ListItemIcon>
				<Icon className={classes.themeIcon}>{icon}</Icon>
			</ListItemIcon>
			<ListItemText primary={name} />
		</ListItem>
	);
};

export default ListItemLink;
