import { Button as MuiButton } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
	type?: string;
	children?: ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
}

const Button: React.FC<Props> = ({ type, children, onClick, disabled }) => {
	return (
		<MuiButton
			className={`button ${type}`}
			onClick={onClick}
			disabled={disabled || false}
		>
			{children}
		</MuiButton>
	);
};

export default Button;
