import getIdToken from 'api/authentication/get-id-token';
import createRequestHeader from 'api/request/create-request-header';
import createUri from 'api/request/create-uri';
import { AxiosRequestConfig } from 'axios';
import doFetchWithCancel from './common/do-fetch-with-cancel';

export const fetcherWithCancel = async (
	axiosConfig: AxiosRequestConfig = {},
	...args: [endpoint: string, searchParams?: { name: string; val }[]]
) => {
	const [endpoint, searchParams] = args;
	const token = await getIdToken();

	const encodedUri = args && createUri(endpoint, searchParams, 'AdminApi');
	const requestHeader = createRequestHeader(token, endpoint, 'AdminApi');

	return await doFetchWithCancel(axiosConfig, token, encodedUri, requestHeader);
};
