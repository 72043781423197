import {
	AppBar,
	Drawer,
	IconButton,
	List,
	Toolbar,
	Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import React from 'react';
import ListItemLink from './list-item-link';
import routes from '../../pages/routes';
import ProfileMenu from './profile-menu';

const Navigation = () => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const environment = window.sessionStorage.getItem('environment');
	const drawer = (
		<List>
			{routes
				.filter(route => !route.hidden)
				.map((route, i) => (
					<ListItemLink {...route} key={i} />
				))}
		</List>
	);

	return (
		<div className="nav__root">
			<AppBar position="fixed" className="nav__appbar" elevation={1}>
				<Toolbar disableGutters={true} className="nav__toolbar">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={() => setMobileOpen(!mobileOpen)}
						className="nav__menu-button"
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap={true}>
						Dignisia Admin {window.location.hostname === 'localhost' && 'LOCAL'}
					</Typography>
					{environment && (
						<Typography variant="h6" noWrap={true}>
							Current environment: {environment}
						</Typography>
					)}
					<ProfileMenu />
				</Toolbar>
			</AppBar>
			<nav className="nav__drawer">
				<div className="temporary">
					<Drawer
						variant="temporary"
						open={mobileOpen}
						onClose={() => setMobileOpen(!mobileOpen)}
						classes={{
							paper: 'nav__drawer-paper',
						}}
						ModalProps={{
							keepMounted: true,
						}}
					>
						{drawer}
					</Drawer>
				</div>
				<div className="permanent">
					<Drawer
						classes={{
							paper: 'nav__drawer-paper',
						}}
						variant="permanent"
						open={true}
					>
						{drawer}
					</Drawer>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;
