import { ApiEndpoint } from 'api/api-endpoint/api-endpoint';
import config from 'config';
import getIsApiEndpointConfigured from './get-is-api-endpoint-configured';

const getSpecificApiUrl = (apiEndpoint: ApiEndpoint = 'AdminApi') => {
	switch (apiEndpoint) {
		case 'AdminApi':
			if (getIsApiEndpointConfigured(apiEndpoint)) {
				return config.apiUrl;
			} else {
				break;
			}
	}
	return config.apiUrl;
};

const getApiUri = (apiEndpoint: ApiEndpoint = 'AdminApi') => {
	return getSpecificApiUrl(apiEndpoint);
};

const isLocalApiRequest = (url: string) => {
	return (url && url.includes('localhost')) || url.endsWith(':7071/api');
};

export const createUri = (
	uri: string,
	searchParams: { name: string; val: string }[] | null | undefined,
	apiEndpoint?: ApiEndpoint,
) => {
	const apiUrl = getApiUri(apiEndpoint);
	const encodedUri = uri.startsWith('http')
		? new URL(uri)
		: new URL(`${apiUrl}${uri}`);

	if (isLocalApiRequest(apiUrl)) {
		// Add username to request to ensure that the API works locally
		encodedUri.searchParams.append('userName', process.env.REACT_APP_USERNAME);
	}

	if (searchParams && Array.isArray(searchParams)) {
		searchParams
			.filter(arg => Boolean(arg.val))
			.map(arg => encodedUri.searchParams.append(arg.name, arg.val));
	}

	const environment = window.sessionStorage.getItem('environment');
	if (environment) {
		encodedUri.searchParams.append('environment', environment);
	}

	return encodedUri;
};

export default createUri;
