import React, { useEffect } from 'react';
import { LogoComponent } from '@dignisia/dui';
import Button from 'components/button';
import { useSetOriginalPathRedirect } from 'hooks/use-original-path-redirect';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom'; // Update the import
import { InteractionStatus } from '@azure/msal-browser';

interface Props {
	isAuthenticated: boolean;
	progress: InteractionStatus;
	onSignIn: () => void;
}

const LoginPage: React.FC<Props> = ({
	isAuthenticated,
	progress,
	onSignIn,
}) => {
	useSetOriginalPathRedirect();

	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
				onSignIn();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, [onSignIn]);

	return (
		<>
			{isAuthenticated && <Navigate to="/" />}
			<LoginContainer>
				<LoginLogoContainer>
					<LogoComponent />
				</LoginLogoContainer>
				<div>
					<Button
						type="primary"
						disabled={progress === 'login'}
						onClick={onSignIn}
					>
						{progress !== 'login' ? 'Login' : 'You are currently logging in'}
					</Button>
				</div>
			</LoginContainer>
		</>
	);
};

const LoginContainer = styled.div`
	background-size: cover;
	text-align: center;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
`;

const LoginLogoContainer = styled.div`
	display: inline-block;
	margin-top: 6.25em;
	margin-bottom: 3.15em;
`;

export default LoginPage;
