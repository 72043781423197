import { msalInstance, MSAL_SCOPES } from 'utils/auth/auth';

const getIdToken = async () => {
	const accounts = msalInstance.getAllAccounts();

	if (accounts.length > 0) {
		const request = {
			scopes: MSAL_SCOPES,
			account: accounts[0],
		};
		const idToken = await msalInstance
			.acquireTokenSilent(request)
			.then(response => {
				return response.idToken;
			})
			.catch(error => {
				// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
				console.log('', error);
				return null;
			});

		return idToken;
	}

	return null;
};

export default getIdToken;
