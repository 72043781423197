import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePersistedState from 'hooks/use-persisted-state';
import useAuthProvider from './use-auth-provider';

const useSetOriginalPathRedirect = () => {
	const location = useLocation();
	const from = location.state?.from;
	const [, setPersistedFrom] = usePersistedState(
		0,
		'login/backRedirect',
		null,
		'sessionStorage',
	);
	const [, setDoRedirect] = usePersistedState(
		0,
		'login/doRedirect',
		true,
		'sessionStorage',
	);

	useEffect(() => {
		if (from) {
			setPersistedFrom(from);
			setDoRedirect(true);
		}
	}, [from, setDoRedirect, setPersistedFrom]);
};

const useOriginalPathRedirect = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { account, progress, isAuthenticated } = useAuthProvider();

	const from = location.state?.from;
	const [persistedFrom, , clearPersistedFrom] = usePersistedState(
		0,
		'login/backRedirect',
		null,
		'sessionStorage',
	);
	const [doRedirect, setDoRedirect] = usePersistedState(
		0,
		'login/doRedirect',
		true,
		'sessionStorage',
	);

	useEffect(() => {
		if (isAuthenticated && progress !== 'login' && doRedirect) {
			const resolvedFrom = from ?? persistedFrom ?? { pathname: '/' };
			navigate(resolvedFrom.pathname);
			setDoRedirect(false);
			clearPersistedFrom();
		}
	}, [
		navigate,
		isAuthenticated,
		account,
		location,
		progress,
		from,
		persistedFrom,
		clearPersistedFrom,
		doRedirect,
		setDoRedirect,
	]);
};

export { useSetOriginalPathRedirect, useOriginalPathRedirect };
